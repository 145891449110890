<template>
  <div class="tag-list">
    <l-title-card
        :name="$route.meta.title"
    >
      <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addTag"
      >
        添加
      </el-button>
    </l-title-card>
    <div class="main-content-container">
      <el-table
          :data="list"
          border
          stripe
      >
        <el-table-column
            label="序号"
            type="index"
            width="50px"
        >
        </el-table-column>
        <el-table-column
            label="标签名称"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="文章数量"
            prop="num"
        >
          <template slot-scope="scope">
            {{ formatArticleNum(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.createTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="更新时间"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.updateTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="120px"
        >
          <template slot-scope="scope">
            <l-icon
                name="icon-bianji"
                class="edit"
                @click="editTag(scope.row)"
            >
            </l-icon>
            <l-icon
                name="icon-shanchukai"
                class="delete"
                @click="deleteType(scope.row)"
            >
            </l-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible.sync="showDialog"
        :title="type === 'edit' ? '编辑标签' : '添加标签'"
    >
      <el-form
          :model="dataInfo"
          label-width="80px"
          :rules="rules"
          ref="tagForm"
      >
        <el-form-item
            label="分类名称"
            prop="name"
        >
          <el-input
              v-model="dataInfo.name"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
            type="primary"
            @click="saveData"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formatArticleNum} from "@/tools"

export default {
  name: "index",
  data() {
    return {
      list: [],
      type: "",
      showDialog: false,
      dataInfo: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur"
          }
        ]
      }
    }
  },
  methods: {
    formatArticleNum,
    /**
     * @description 获取分类列表
     */
    async getTagList() {
      this.list = await this.$api.tag.getTagList({
        pageSize: 10,
        pageNo: 1
      });
    },
    /**
     * @description 删除分类
     * @param itemData
     */
    deleteType(itemData) {
      this.$confirm(`你确定要删除${itemData.name}?`, "温馨提示", {
        type: "warning"
      }).then(async () => {
        const result = await this.$api.tag.deleteTagInfo(itemData)
        if (result) {
          this.$notify({
            type: "success",
            title: "操作成功",
            message: `${itemData.name}已删除成功`
          })
          this.getTagList();
        }
      }).catch(() => {
        console.warn("放弃执行了")
      })
    },
    /**
     * @description 编辑标签信息
     * @param itemData
     */
    editTag(itemData) {
      this.showDialog = true;
      this.type = "edit";
      this.dataInfo = Object.assign({}, itemData)
    },
    /**
     * @description 添加标签信息
     */
    addTag() {
      this.showDialog = true;
      this.type = "add"
      this.dataInfo = {
        name: null
      };
    },
    /**
     * @description 执行保存
     */
    saveData() {
      this.$refs.tagForm.validate(async valid => {
        if (valid) {
          if (this.type === "edit") {
            await this.editTagInfo()
          } else {
            await this.addNewTag()
          }
          this.showDialog = false;
          this.getTagList();
        }
      })
    },
    /**
     * @description 编辑标签信息
     * @return {Promise<void>}
     */
    async editTagInfo() {
      const result = await this.$api.tag.editTagInfo(this.dataInfo);
      if (result) {
        this.$notify({
          type: "success",
          title: "编辑标签成功"
        })
      }
    },
    /**
     * @description 添加新的标签数据
     * @return {Promise<void>}
     */
    async addNewTag() {
      const result = await this.$api.tag.addTagInfo(this.dataInfo);
      if (result) {
        this.$notify({
          type: "success",
          title: "添加标签成功"
        })
      }
    }
  },
  created() {
    this.getTagList();
  }
}
</script>

<style lang="scss">

</style>

/**
 * @description: 格式化分类的展示
 */
export const formatTypeName = (dataInfo) => {
    return dataInfo?.typeInfo?.name || ""
}

/**
 * @description: 格式化标签展示
 */
export const formatTagName = (dataInfo) => {
    const name = dataInfo?.tagList?.map(item => {
        return item.name;
    })
    return name.join("、")
}

/**
 * @description: 格式化文章数量展示
 */
export const formatArticleNum = (dataInfo) => {
    return dataInfo.articleList?.length || 0
}

/**
 * @description: 格式化文章字数展示
 */
export const formatArticleLength = (dataInfo) => {
    return dataInfo?.content?.length || 0
}